import React from 'react';
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import Layout from "../components/layout"
import Title from '../components/styles/Title'

export default function Terms({location}) {

    return (
        <Layout>
            <GatsbySeo canonical={location.href} title="NaturalVoicer Terms and Conditions" />
            <div className="bg-light">
                <div className="mx-auto max-w-[1000px] px-4 w-full pt-[124px] pb-20">
                    <div>
                        <div className="bg-white shadow-block px-[30px] pt-10 pb-20 sm:px-4">
                            <h1 css={Title("h1")} className="pb-5">Terms and Conditions</h1>
                            <p className="pb-5">By accessing this website, you agree to comply with and be bound by the Naturalvoicer (“company”, “we”, “us” or “our”) terms and conditions. Naturalvoicer products are protected by copyright laws and international copyright treaties, as well as other intellectual property laws and treaties. We reserve the right to change or notify these terms of use from time to time at our sole discretion.</p>
                            <h2 className="pt-[30px] text-2xl pb-5 font-raleway font-extrabold">COPYRIGHT</h2>
                            <p className="pb-5">This website and its products (including, but not limited to any audios, images, photos, animations, videos, music, texts) are protected by copyright laws and international treaty provisions. </p>
                            <p className="pb-5">The voices provided in the free trial are licensed for personal use only. Only the audio files generated using a paid subscription to Naturalvoicer paid version are licensed to allow public and commercial purposes and/or redistribution.</p>
                            <h2 className="pt-[30px] text-2xl pb-5 font-raleway font-extrabold">USER REPRESENTATIONS</h2>
                            <p className="pb-5">By using this website, you declare and guarantee: </p>
                            <ol className="list-num pb-5">
                                <li>all registration information you submit is true, accurate and complete; </li>
                                <li>you will maintain the accuracy of such information and update such registration information when necessary;</li>
                                <li>you are not a minor in the jurisdiction where you live; </li>
                                <li>you will not access this website through automatic or non-manual means, whether through robots, scripts or other means; </li>
                                <li>you will not use this website is used for any illegal or unauthorized purpose;</li>
                                <li>your use of this website will not violate any applicable laws or regulations;</li>
                            </ol>
                            <p className="pb-5">If you provide any untrue, inaccurate, non-updated or incomplete information, we have the right to suspend or terminate your account and refuse any and all current or future use of this website.</p>
                            <h2 className="pt-[30px] text-2xl pb-5 font-raleway font-extrabold">USER DATA</h2>
                            <p className="pb-5">We will maintain certain data that you transmit to this website to manage the performance of this website and data related to your use of this website. Although we will routinely back up data on a regular basis, you are fully responsible for all data transmitted or all data related to any activities you use this website. You agree that we will not be liable to you for any loss or damage of any such data, and you hereby waive any right to take action against us for any such loss or damage of such data.</p>
                            <h2 className="pt-[30px] text-2xl pb-5 font-raleway font-extrabold">DISCLAIMER </h2>
                            <p className="pb-5">The site is provided on an as-is and as-available basis. You agree that your use of the site and our services will be at your sole risk. To the fullest extent permitted by law, we disclaim all warranties, express or implied, in connection with the site and your use thereof, including, without limitation, the implied warranties of merchantability, fitness for a particular purpose, and non-infringement. We make no warranties or representations about the accuracy or completeness of the site’s content or the content of any websites linked to the site and we will assume no liability or responsibility for any </p>
                            <ol className="list-num pb-5">
                                <li>errors, mistakes, or inaccuracies of content and materials;</li>
                                <li>personal injury or property damage, of any nature whatsoever, resulting from your access to and use of the site;</li>
                                <li>any unauthorized access to or use of our secure servers and/or any and all personal information and/or financial information stored therein;</li>
                                <li>any interruption or cessation of transmission to or from the site;</li>
                                <li>any bugs, viruses, trojan horses, or the like which may be transmitted to or through the site by any third party;</li>
                                <li>any errors or omissions in any content and materials or for any loss or damage of any kind incurred as a result of the use of any content posted, transmitted, or otherwise made available via the site;</li>
                            </ol>
                            <p className="pb-5">We do not warrant, endorse, guarantee, or assume responsibility for any product or service advertised or offered by a third party through the site, any hyperlinked website, or any website or mobile application featured in any banner or other advertising, and we will not be a party to or in any way be responsible for monitoring any transaction between you and any third-party providers of products or services. </p>
                            <p className="pb-5">As with the purchase of a product or service through any medium or in any environment, you should use your best judgment and exercise caution where appropriate.</p>
                            <h2 className="pt-[30px] text-2xl pb-5 font-raleway font-extrabold">LINKS TO OTHER WEBSITES</h2>
                            <p className="pb-5">The Site may contain (or you may be sent via the Site) links to other websites (“third-party websites”) as well as articles, photographs, text, graphics, designs, music, sound, video, information, applications, software, and other content or items belonging to or originating from third parties (“third-party content”). </p>
                            <p className="pb-5">Such third-party websites and third-party content are not investigated, monitored, or checked for accuracy, appropriateness, or completeness by us, and we are not responsible for any third-party websites accessed through the Site or any third-party content posted on, available through, or installed form the Site, including the content, accuracy, offensiveness, opinions, reliability, privacy practices, or other policies of or contained in the third-party websites or the third-party content. </p>
                            <p className="pb-5">Inclusion of, linking to, or permitting the use of the installation of any third-party websites or any third-party content does not imply approval or endorsement thereof by us. </p>
                            <p className="pb-5">If you decide to leave the Site and access the third-party websites or to use or install any third-party content, you do so at your own risk, and you should be aware these Terms of Use no longer govern. </p>
                            <p className="pb-5">You should review the applicable terms and policies, including privacy and data gathering practices, of any website to which you navigated from the Site or relating to any applications you use of install form the Site. </p>
                            <p className="pb-5">Any purchases you make through third-party websites will be through other websites and from other companies, and we take no responsibility whatsoever in relation to such purchases which are exclusively between you and the applicable third party. </p>
                            <p className="pb-5">You agree and acknowledge that we do not endorse the products or services offered on third-party websites and you shall hold us harmless from any harm caused by your purchase or such products or services. </p>
                            <p className="pb-5">Additionally, you shall hold us harmless from any losses sustained by you or harm caused to your relating to or resulting in any way from any third-party content or any contact with third-party websites.</p>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

